import React, { Component } from "react";
import toPairs from "lodash/toPairs";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";
import Cookies from "../../functions/cookies";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import sendEvent from "../../functions/analytics";
import { isSSR } from "../NoSSR";
import Button from "../Button";
import "./SignIn.css";

/**
 * Icons imports
 */
import Image from "../Image";
import googleSVG from "./assets/google.svg";
import facebookSVG from "./assets/facebook.svg";
import svgLogo from "./assets/logo.svg";

/**
 * Current showed screen
 * Used with withEmail mod, changes component inners
 * Possible values: `"email"`, `"email-error"`, `"phone"`, `"log-in"`, `"password"`, or `null` for default screen
 * @typedef {String|Null} SignInScreen
 */

/**
 * Available social nets to login with
 * @type {Array}
 */
const socialNets = {
  google: {
    link: "google-oauth2",
    icon: googleSVG,
  },
  facebook: {
    link: "facebook",
    icon: facebookSVG,
  },
};

/**
 * Terms and conditions links
 * @param {Object} $
 * @param {String} $.lang - current language
 */
export function SignInConditions({ lang }) {
  return (
    <div className="SignIn__conditions">
      <Trans>
        By signing in, you agree to&#32;
        <a href={`https://wegotrip.com/${lang}/terms/`} target="_blank">
          Terms & Conditions
        </a>
        &#32;and&#32;
        <a href={`https://wegotrip.com/${lang}/privacy-policy/`} target="_blank">
          Privacy Policy
        </a>
        .
      </Trans>
    </div>
  );
}

/**
 * Sign in with different ways
 * @param {String} backURL - url of the page shown displayed after login
 * @param {String} option - promo option
 * @param {Array[String]} socials - social nets to be shown
 * @param {Array[React::Element]} children - component children, 0 will be inserted before, 1 - after
 */
class SignIn extends Component {
  state = {
    loading: null,
  };

  /**
   * Displays "Loading" on the given service
   * @param {String} service - social net id
   */
  setLoading = service => {
    this.setState({ loading: service });
  };

  render() {
    let { backURL, match } = this.props;
    const { socials = [], children = [], screen, option } = this.props;
    const { loading } = this.state;
    const lang = getCurrentLanguage(match.params.lang);
    if (!isSSR) {
      backURL = get(window, "location.pathname");
    } else {
      backURL = `/${lang}/`;
    }
    const userParams = isSSR ? {} : Cookies.get("userParams");
    const query = toPairs(userParams)
      .map(pair => pair.join("="))
      .join("&");

    return (
      <div className="SignIn">
        <div className="SignIn__header">
          <Image staticImage height="57px" src={svgLogo} loading="lazy" />
          <span>
            {!screen ? <Trans>Sign up in WeGoTrip</Trans> : null}
            {screen === "log-in" ? <Trans>Log in to WeGoTrip</Trans> : null}
          </span>
        </div>
        {children[0]}
        {socials.map(social => {
          const socialId = social.toLowerCase();
          const userQuery = socialId === "facebook" && query ? `&${query}` : "";
          return lang === "ru" && ["facebook"].includes(socialId) ? null : (
            <Button
              key={socialId}
              external
              className="SignIn__option"
              theme="card"
              iconPosition="before"
              icon={
                <Image
                  staticImage
                  className={`SignIn__option-icon SignIn__option-icon--${socialId}`}
                  src={socialNets[socialId].icon}
                  alt={`${social} icon`}
                  loading="lazy"
                />
              }
              to={`/login/${socialNets[socialId].link}/?lang=en&promo=${option}&next=${backURL}${userQuery}`}
              onClick={() => {
                this.setLoading(socialId);
                sendEvent("track", "Login Started", { method: "social", social: socialId });
                if (option === "discount5") sendEvent("track", "Discount5: Topbar started");
              }}
            >
              {loading === socialId ? (
                <Trans>Connecting...</Trans>
              ) : (
                <Trans>Continue with {social}</Trans>
              )}
            </Button>
          );
        })}
        {children[1] || children}
      </div>
    );
  }
}

export default withRouter(SignIn);
