import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { LOGIN_EMAIL } from "../../../stores/types";
import Input from "../../Input";
import Button from "../../Button";
import "./SignInEmail.css";

/**
 * Icons imports
 */
import svgEmail from "../assets/letter.svg";
import svgTick from "../assets/tick.svg";
import Image from "../../Image";

/**
 * Email input errors
 * @type {Object}
 */
const errorMessages = {
  empty: <Trans>This field cannot be empty</Trans>,
  format: <Trans>Please enter valid email address</Trans>,
};

/**
 * Displays offer to fill form with email
 */
class SignInEmail extends Component {
  /**
   * Inner state of the class
   * @prop {Boolean} sent - email message with link was sent successfully
   * @prop {String} email - current entered in email field text
   * @prop {React::Component} error - error message from email input field
   */
  state = {
    error: null,
    sent: false,
    email: "",
  };

  /**
   * Setting up email, if it given
   */
  componentDidMount() {
    const { email } = this.props;
    if (email) this.updateEmail({ target: { value: email } });
  }

  /**
   * Sends request to the server with user email
   * Checks email validity
   */
  sendEmail = event => {
    event.preventDefault();

    const { email } = this.state;
    const {
      dispatch,
      backURL,
      history: { location },
      option,
    } = this.props;

    if (!email) {
      this.setState({ error: errorMessages.empty });
    } else if (!/^[^@]+@[^@]+$/.test(email)) {
      this.setState({ error: errorMessages.format });
    } else {
      this.setState({ sent: true });
      dispatch({ type: LOGIN_EMAIL, email, next: backURL || location.pathname, promo: option });
    }
  };

  /**
   * Updates email in state
   * Tracks input changes
   * @param {Event} $ - onChange event
   * @param {Element} $.target - input element
   */
  updateEmail = ({ target }) => {
    this.setState({ email: target.value, error: null });
  };

  render() {
    const { screen, i18n } = this.props;
    const { sent, email, error } = this.state;

    if (!sent) {
      let header = <Trans>Sign in with email</Trans>;
      let content = (
        <Trans>
          You don&apos;t need to you remember a password. Enter your email address and we&apos;ll
          send you a magic link. If you have already purchased tickets, please use the same email
          address.
        </Trans>
      );
      let button = <Trans>Send magic link</Trans>;

      if (screen === "email-error") {
        header = <Trans>Ooops</Trans>;
        content = (
          <Trans>
            Something went wrong during signing in process via email. Perhaps, your link has
            expired.
          </Trans>
        );
        button = <Trans>Try again</Trans>;
      }

      return (
        <form className="SignInEmail" onSubmit={this.sendEmail}>
          <div className="SignIn__header">
            <Image staticImage src={svgEmail} loading="lazy" />
            <span>{header}</span>
          </div>
          {content}
          <Input
            className="SignInEmail__email"
            placeholder={i18n._(t`Your email`)}
            theme="card"
            value={email}
            onChange={this.updateEmail}
          />
          {error && <div className="SignInEmail__error">{error}</div>}
          <Button className="SignInEmail__send" theme="card" type="submit">
            {button}
          </Button>
        </form>
      );
    }

    return (
      <div className="SignInEmail">
        <div className="SignIn__header">
          <Image staticImage src={svgTick} loading="lazy" />
          <span>
            <Trans>Check your email</Trans>
          </span>
        </div>
        <div className="SignInEmail__ok">
          <Trans>
            We&apos;ve sent a verification link to <b>{email}</b>
          </Trans>
        </div>
        <div className="SignInEmail__offer">
          <Trans>Please check your email and click the magic link to sign in.</Trans>
        </div>
        <Button className="SignInEmail__retry" onClick={() => this.setState({ sent: false })}>
          <Trans>Try different email</Trans>
        </Button>
      </div>
    );
  }
}

/**
 * Create actions to change store
 * @param {Function} dispatch
 */
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withI18n()(withRouter(connect(() => ({}), mapDispatchToProps)(SignInEmail)));
