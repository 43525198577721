import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import classNames from "../../functions/classNames";
import withProps from "../../functions/react/withProps";
import isSSR from "../../constants/env/ssr";
import { Icon } from "../Icon";

import "./Popup.css";
import Button from "../Button";

/**
 * Popup
 * This implementation assumes that it will be only one active popup at the page
 * If there are more, body scroll may be unlocked before all popups are closed
  @component
 * @param {Object} props - Component properties
 * @param {string} [props.className] - Additional className for styling purposes
 * @param {string} [props.theme] - CSS theme of the component (`"return", "bottomSheet"`)
 * @param {(React.Element|string)} [props.title] - Title of the popup
 * @param {boolean} [props.rendered] - If `true`, the popup will always be in the HTML
 * @param {boolean} [props.closable=true] - Indicates whether the popup has a close button
 * @param {boolean} [props.backButton=false] - Determines whether to show or hide the back button
 * @param {number} [props.animate=200] - Time for animation in milliseconds; if not set, no animation will be presented
 * @param {number} [props.hideAnimationSpeed] - Speed for hiding animation in milliseconds
 * @param {React.Element} [props.triggerElement] - Element that triggers the open/close of the popup on click
 * @param {Object} [props.bottomButtonProps] - Properties of the bottom button, such as `text`, `willClose`, `onClick`, `includeInChildren`, and other props
 * @param {Object} [props.altBottomButtonProps] - Properties of an alternate bottom button
 * @param {Function} [props.onClosed=() => {}] - Callback function when the close button is pressed
 * @param {Function} [props.onOpened=() => {}] - Callback function when the popup is opened
 * @param {Function} [props.onBackButtonClick=() => {}] - Callback function when the back button is pressed
 * @param {React.Ref} forwardedRef - Forwarded reference to expose imperative methods
 * @returns {React.Element} Rendered Popup component
*/
const Popup = (
  {
    theme,
    className,
    open,
    bodyClassName,
    title,
    subTitle,
    children,
    triggerElement,
    triggerClassName,
    rendered,
    closable = true,
    backButton = false,
    animate = 200,
    bottomButtonProps,
    altBottomButtonProps,
    noButtonWrap = true,
    onClosed = () => {},
    onOpened = () => {},
    onBackButtonClick = () => {},
    ...props
  },
  forwardedRef,
) => {
  const hideAninationSpeed = animate ? animate / 1.5 : 0;
  // Manages popup open/close
  const [isOpened, setOpened] = useState(false);
  // Manages popup appearing/disappearing class
  const [isDisappearing, setDisappearing] = useState(false);

  const handleOpen = useCallback(() => {
    onOpened();
    setOpened(true);
  }, [onOpened]);

  const handleClose = useCallback(() => {
    setDisappearing(true);
    onClosed();

    // Set popup !opened after timeout to show hide animation
    const timeoutId = setTimeout(() => {
      setOpened(false);
      setDisappearing(false);
      // timeout is less than animation speed to prevent blinking
    }, hideAninationSpeed / 1.5);
    return () => clearTimeout(timeoutId);
  }, [hideAninationSpeed, onClosed]);

  const closeOnEscape = useCallback(
    event => {
      if (event.key === "Escape" && closable) handleClose();
    },
    [closable, handleClose],
  );

  useEffect(() => {
    if (isSSR) return () => {};
    if (isOpened) document.addEventListener("keyup", closeOnEscape);
    else document.removeEventListener("keyup", closeOnEscape);

    return () => {
      document.removeEventListener("keyup", closeOnEscape);
    };
  }, [isOpened, closeOnEscape]);

  const hasReturn = useMemo(
    () => backButton || (closable && theme === "return"),
    [backButton, closable, theme],
  );

  const animationDuration = useMemo(
    () => (isDisappearing ? `${hideAninationSpeed}ms` : `${animate}ms`),
    [animate, hideAninationSpeed, isDisappearing],
  );
  const isVisible = isOpened || open;
  const bottomButtons = [altBottomButtonProps, bottomButtonProps];
  const ButtonsWrapperTag = bottomButtons.every(Boolean) ? "div" : Fragment;

  useImperativeHandle(forwardedRef, () => ({ handleOpen, handleClose }), [handleOpen, handleClose]);

  return (
    !isSSR && (
      <>
        {triggerElement &&
          (noButtonWrap ? (
            withProps(triggerElement)({ onClick: handleOpen })
          ) : (
            <Button
              className={classNames(
                "DropdownButton__button",
                !props.icon && "DropdownButton__button--chevron",
                triggerClassName,
              )}
              theme="simple"
              iconPosition="after"
              icon={<Icon name={`chevron/${isOpened ? "up" : "down"}`} />}
              onClick={handleOpen}
              {...props}
            >
              {triggerElement}
            </Button>
          ))}
        {(isOpened || isVisible || rendered) &&
          ReactDOM.createPortal(
            <div
              style={{ animationDuration }}
              className={classNames(
                "Popup",
                isVisible && "Popup--visible",
                animate && isVisible && !isDisappearing && "Popup--appearing",
                animate && isVisible && isDisappearing && "Popup--disappearing",
                theme && `Popup--${theme}`,
                className,
              )}
              onClick={closable ? handleClose : undefined}
            >
              <div
                style={{ animationDuration }}
                className="Popup__window"
                onClick={event => event.stopPropagation()}
              >
                {hasReturn && (
                  <Button
                    className="Popup__button Popup__button--back"
                    theme="simple"
                    icon={<Icon name="arrow/left" />}
                    onClick={theme === "return" ? handleClose : onBackButtonClick}
                  />
                )}
                <div className="Popup__header">
                  {closable && theme !== "return" && (
                    <Button
                      className="Popup__button Popup__button--close"
                      theme="simple"
                      icon={<Icon name="close/white" width="21" height="21" />}
                      onClick={handleClose}
                    />
                  )}
                  {title && <div className="Popup__title">{title}</div>}
                </div>
                {subTitle}
                <div className={classNames("Popup__content", bodyClassName)}>{children}</div>
                {!!bottomButtons.length && (
                  <ButtonsWrapperTag
                    {...(ButtonsWrapperTag === "div"
                      ? { className: "Popup__content__bottomButtons" }
                      : {})}
                  >
                    {bottomButtons.map(
                      ({
                        willClose,
                        onClick,
                        children: buttonChildren,
                        isAlt,
                        ...buttonProps
                      } = {}) =>
                        buttonChildren && (
                          <Button
                            key={buttonChildren}
                            {...buttonProps}
                            className={classNames(
                              "Popup__content__bottomButton",
                              isAlt && "Popup__content__bottomButton--alt",
                              buttonProps.className,
                            )}
                            size="L"
                            onClick={() => {
                              if (onClick) onClick();
                              if (willClose) handleClose();
                            }}
                          >
                            {buttonChildren}
                          </Button>
                        ),
                    )}
                  </ButtonsWrapperTag>
                )}
              </div>
            </div>,
            document.body,
          )}
      </>
    )
  );
};

export default forwardRef(Popup);
